import React, { FC, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import IconCustom from 'components/common/IconCustom';
import { isBrowser } from 'utils/browser';
import { INewsletterPopupProps, NEWSLETTER_HAS_SHOWN } from './model.d';

import './NewsletterPopup.scss';

const SHOW_POPUP_AFTER = 10000;

const NewsletterPopup: FC<INewsletterPopupProps> = ({ wayinFormSrc, wayinFormId }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isWayinForm = !!wayinFormSrc?.length && !!wayinFormId?.length;

  useEffect(() => {
    const shouldShowNewsletter = isBrowser() && !Cookies.get(NEWSLETTER_HAS_SHOWN) && isWayinForm;
    if (!shouldShowNewsletter) {
      return;
    }

    const t = setTimeout(() => {
      setIsVisible(true);
    }, SHOW_POPUP_AFTER);

    return () => {
      clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://xd.wayin.com/ui/ngx.embed.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isVisible]);

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isBrowser()) {
      Cookies.set(NEWSLETTER_HAS_SHOWN, '1', { expires: 7 });
    }
    setIsVisible(false);

    if (isWayinForm) {
      window.location.reload();
    }
  };

  const stopEvents = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    const addScroll = () => {
      document.body.classList.remove('no-scroll');
    };

    return addScroll;
  }, [isVisible]);

  return isVisible ? (
    <div className="newsletter" data-testid="newsletter" role="presentation" onClick={handleClose}>
      <div className="newsletter__popup" role="presentation" onClick={stopEvents}>
        <button type="button" className="newsletter__popup-close" onClick={handleClose}>
          <IconCustom icon="close_X_icon" />
        </button>
        <iframe
          title="Wayin form"
          src={wayinFormSrc}
          frameBorder="0"
          scrolling="no"
          width="100%"
          height="100%"
          id={wayinFormId}
        />
      </div>
    </div>
  ) : null;
};

export default NewsletterPopup;
